<template>
  <div id="app">
    <v-app id="inspire">
      <v-app id="inspire">
        <v-main>
          <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                  <v-toolbar color="primary" dark flat>
                    <v-toolbar-title>Login</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <v-form ref="form" v-model="valid">
                      <v-text-field
                        label="Username"
                        name="username"
                        v-model="username"
                        prepend-icon="mdi-account"
                        type="text"
                        :rules="usernameRules"
                        required
                      ></v-text-field>

                      <v-text-field
                        id="password"
                        label="Password"
                        name="password"
                        v-model="password"
                        prepend-icon="mdi-lock"
                        :rules="passwordRules"
                        type="password"
                        required
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="login">Login</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </v-app>
  </div>
</template>

<script>
import AuthServices from '@/services/AuthServices'
import { mapMutations } from 'vuex'
import { FormMixin } from '@/Mixins/FormMixin'

export default {
  name: 'LoginPage',
  mixins: [FormMixin],
  data() {
    return {}
  },
  methods: {
    ...mapMutations({ setToken: 'AuthStore/setToken' }),
    login() {
      if (this.$refs.form.validate()) {
        AuthServices.AuthLogin({
          username: this.username,
          password: this.password,
        })
          .then(resp => {
            if (resp.status === 200) {
              const accessToken = resp.data.tokenKey
              this.setToken(accessToken)
              location.reload()
            }
          })
          .catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: `${err.message}`,
            })
          })
      }
    },
  },
}
</script>
